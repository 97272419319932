import React from "react";
import Layout from "../../components/layout";
import SearchEngineOptimization from "../../components/SearchEngineOptimization";
import ContactForm from "../../components/Forms/ContactForm";
import PolygonContact from "../../images/polygons/polygon-contact-form.svg";
import "./styles.scss";
import CHS from "./CHS_Logo.png";
import CPS from "./CPS.png";
import Garrett from "./Garrett_logo.png";
import GreenBay from "./Greenbay.png";
import HED from "./HED.png";
import Wild from "./Wild_Logo.png";
import Office from "./our-office.png";
import Maps from "./Maps.png";
import ParkingLot from "./Parkinglot.png";
import OfficeOverHead from "./OfficeOverHeadShot.png";

const ContactPage = () => {
	const photos = [
		{
			image: ParkingLot,
			header: "From the parking lot",
			steps: [
				"Head to the main entrance on the East face of the building.",
				"Once inside, head straight back to the large elevator and take it up to the 3rd floor. (Bring a book, this elevator is on Spanish time).",
			],
		},
		{
			image: OfficeOverHead,
			header: "Next Steps",
			steps: [
				"Head out the elevator and follow the hallway to the right. If you seen green, you've made it to the right spot.",
			],
		},
	];

	return (
		<Layout>
			<SearchEngineOptimization title="Contact us" />
			<div className="contact">
				<section className="section section--is-spacious">
					<div className="container">
						<div className="text-center">
							<div className="contact__subheading">You've made it this far</div>
							<h1 className="contact__heading">
								Let's take it to the next level
							</h1>
							<div className="polygon polygon--contact">
								<img
									className="polygon__image"
									src={PolygonContact}
									alt=""
									aria-hidden="true"
								/>
							</div>
						</div>
					</div>
					<ContactForm />
				</section>
				<section className="section">
					<div className="container">
						<div className="contact__trusted-by">
							<h2 className="contact__trusted-by-heading">Trusted by</h2>
							<div className="contact__trusted-by-list">
								<img src={CHS} alt="CHS" />
								<img src={GreenBay} alt="Green Bay" />
								<img src={Garrett} alt="Garrett" />
								<img src={CPS} alt="CPS" />
								<img src={HED} alt="HED" />
								<img src={Wild} alt="Wild" />
							</div>
						</div>
					</div>
				</section>
				<section className="section">
					<div
						className="contact__our-office-bg"
						style={{ background: `url(${Office})` }}
					>
						<div className="container">
							<div className="contact__our-office">
								<h2>Our Office</h2>
							</div>
						</div>
					</div>
					<div className="container">
						<div className="contact__directions">
							<div className="contact__directions-map">
								<img src={Maps} alt="Map" />
							</div>
							<div className="contact__directions-text">
								<h3>Where we at?</h3>
								<div>
									{"5320 W 23rd Street"} {"Suite 300"}
								</div>
								<div>
									{"Minneapolis"}, {"MN"} {"55416"}
								</div>
								<div className="contact__directions-phone">
									<a href={`tel:612-200-1015`}>612-200-1015</a>
								</div>
								<div>
									<a href={`mailto:info@irishtitan.com`}>info@irishtitan.com</a>
								</div>
								<div className="contact__directions-btn">
									<a
										className="btn btn--black-green"
										href="https://www.google.com/maps/place/Irish+Titan/@44.9603245,-93.3512442,17z/data=!4m6!3m5!1s0x87f623e5b7db9321:0x9c2c83fd18eed898!8m2!3d44.9603245!4d-93.3490555!16s%2Fg%2F1tznmxpy?entry=ttu"
									>
										Directions
									</a>
								</div>
							</div>
						</div>
					</div>
				</section>
				<section className="section">
					<div className="container">
						<div className="text-center">
							<div className="contact__subheading">Visiting us</div>
							<h2 className="contact__heading">Here’s how to find our door</h2>
						</div>

						{photos.map((section, index) => {
							const { header, image, steps } = section;
							return (
								<div
									className={`contact__how-to-find-us ${
										index % 2 === 1
											? "contact__how-to-find-us--even"
											: "contact__how-to-find-us--odd"
									}`}
									key={index}
								>
									<div className="contact__how-to-find-us-photo">
										<img src={image} alt="" />
									</div>
									<div className="contact__how-to-find-us-info">
										<h3>{header}</h3>
										<div className="markdown">
											{steps.map((step, index) => {
												return (
													<ul key={index}>
														<li>{step}</li>
													</ul>
												);
											})}
										</div>
									</div>
								</div>
							);
						})}
					</div>
				</section>
			</div>
		</Layout>
	);
};

export default ContactPage;
